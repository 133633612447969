<template>
    <div>
        <v-col
            class="pa-0"
            cols="4"
        >
            <v-btn
                icon
                color="white"
                href="https://www.facebook.com/myguitare"
                target="_blank"
            >
                <v-icon>mdi-facebook</v-icon>
            </v-btn>
        </v-col>
        <v-col
            class="pa-0"
            cols="4"
        >
            <v-btn
                icon
                color="white"
                href="https://www.youtube.com/channel/UCrPhaD3Lp5x8vOnniHCQgmA"
                target="_blank"
            >
                <v-icon>mdi-youtube</v-icon>
            </v-btn>
        </v-col>
        <v-col
            class="pa-0"
            cols="4"
        >
            <v-btn
                icon
                color="white"
                href="http://myguitare.com"
                target="_blank"
            >
                <v-icon>mdi-application</v-icon>
            </v-btn>
        </v-col>
    </div>
</template>

<style lang="scss">

</style>

<script>
    export default {
        name: 'mg-social-links',

        components: {

        },
    }
</script>
