<template>
    <div>
        <v-col
            class="pa-0"
            cols="4"
        >
            <v-btn
                icon
                color="white"
                href="https://www.facebook.com/mypianopop"
                target="_blank"
            >
                <v-icon>mdi-facebook</v-icon>
            </v-btn>
        </v-col>
        <v-col
            class="pa-0"
            cols="4"
        >
            <v-btn
                icon
                color="white"
                href="https://www.instagram.com/mypianopop/"
                target="_blank"
            >
                <v-icon>mdi-instagram</v-icon>
            </v-btn>
        </v-col>
        <v-col
            class="pa-0"
            cols="4"
        >
            <v-btn
                icon
                color="white"
                href="https://www.youtube.com/channel/UC5l6qoVPr2IO3sAHefwMSrQ"
                target="_blank"
            >
                <v-icon>mdi-youtube</v-icon>
            </v-btn>
        </v-col>
        <v-col
            class="pa-0"
            cols="4"
        >
            <v-btn
                icon
                color="white"
                href="https://www.mypianopop.fr"
                target="_blank"
            >
                <v-icon>mdi-application</v-icon>
            </v-btn>
        </v-col>
    </div>
</template>

<style lang="scss">

</style>

<script>
    export default {
        name: 'mpp-social-links',

        components: {},
    }
</script>
