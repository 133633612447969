<template>
    <v-card flat class="d-flex">
        <v-avatar v-if="$vuetify.breakpoint.smAndUp || !hideImageForMobile" size="125" tile>
            <v-img :src="getBackground(product)"
                   style="border-top-left-radius: 10px; border-bottom-left-radius: 10px">
            </v-img>
        </v-avatar>

        <v-card outlined
                width="100%"
                :class="[$vuetify.breakpoint.mdAndUp || !hideImageForMobile ? 'no-radius-left no-border-left' : '', $vuetify.breakpoint.mdAndUp ? 'd-flex flex-no-wrap justify-space-between' : '']">
            <v-list-item class="pl-6">
                <v-list-item-content>
                    <v-list-item-title class="mb-0 text-h5 font-weight-bold text-wrap">
                        {{ product.title }}
                    </v-list-item-title>

                    <v-list-item-subtitle v-html="description"
                                          class="pt-1 pb-3 mb-0 text-wrap paragraph-no-margin">
                    </v-list-item-subtitle>

                    <div v-if="product.tagStyles && product.tagStyles.data">
                        <template v-for="(tag,index) in product.tagStyles.data">
                            <v-chip small
                                    :key="index"
                                    :color="getTagColor(tag.title)"
                                    class="body-2 mr-2">
                            <span :style="getTagTextStyle(tag.title)">
                                {{ tag.title }}
                            </span>
                            </v-chip>
                        </template>
                        <v-chip small
                                v-if="product.tagLevel && product.tagLevel.data"
                                :color="getTagColor(product.tagLevel.data.title)"
                                class="body-2 mr-2">
                            <span :style="getTagTextStyle(product.tagLevel.data.title)">
                                {{ product.tagLevel.data.title }}
                            </span>
                        </v-chip>
                    </div>
                </v-list-item-content>
            </v-list-item>
            <v-card-actions :class="[$vuetify.breakpoint.mdAndUp ? 'float-right' : '']" style="min-width: 208px">
                <v-list-item class="pa-0">
                    <v-list-item-content>
                        <v-list-item-title class="text-center">
                            <v-btn
                                v-if="product.thrivecart_url && product.thrivecart_enabled"
                                @click="redirectToThriveCart(product.thrivecart_url)"
                                class="px-5"
                                depressed small rounded color="primary">
                                S'inscrire
                            </v-btn>
                            <v-btn
                                v-else-if="!hasFullAccess && !isFree"
                                :to="funnelLink"
                                @click="$emit('closeGlobalSearch')"
                                class="px-5"
                                depressed small rounded color="primary">
                                S'inscrire
                            </v-btn>
                            <v-btn
                            v-else-if="isFree"
                                :to="funnelLink"
                                class="px-5"
                                @click="$emit('closeGlobalSearch')"
                                depressed small rounded color="success"
                                @mouseover="freeBtnHover = product"
                                @mouseleave="freeBtnHover = null"
                            >
                                <span v-if="freeBtnHover && freeBtnHover.id === product.id">Commencer</span>
                                <span v-else class="px-3">Gratuit</span>
                            </v-btn>
                            <v-btn
                                v-else
                                @click="$emit('addProductCourses', product);$emit('closeGlobalSearch')"
                                class="px-5"
                                depressed small rounded color="primary">
                                S'inscrire
                            </v-btn>
                        </v-list-item-title>

                        <v-list-item-title
                            v-if="product.external_link || product.wf_course_id"
                            class="font-weight-bold text-center primary--text text-subtitle-1 pt-2">
                            <router-link
                                v-if="product.wf_course_id"
                                @click="$emit('closeGlobalSearch')"
                                :to="{name:'member-course-detail', params: {
                                        courseUuid: product.slug
                                    }}"
                                class="text--no-decoration">
                                En savoir plus
                            </router-link>
                            <a
                                v-else
                                @click="$emit('closeGlobalSearch')"
                                :href="product.external_link"
                                target="_blank"
                                class="text--no-decoration">
                                En savoir plus
                            </a>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-card-actions>
        </v-card>
    </v-card>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";

    export default {
        name: 'mg-product-item',

        props: {
            value: Object,
            selectedItemId: String,
            hasFullAccess: Boolean,
            hideImageForMobile: Boolean,
        },

        data: () => ({
            Helpers,

            /* DATA TEST */
            cardBg: [
                require('@/assets/default-bg.jpg'),
                require('@/assets/blues-bg.jpg'),
                require('@/assets/funk-bg.png'),
                require('@/assets/metal-bg.png'),
                require('@/assets/pop-bg.png'),
                require('@/assets/rock-bg.png'),
                require('@/assets/variete-francaise-bg.png'),
            ],
            freeBtnHover: null,
        }),

        computed: {
            product() {
                return this.value;
            },

            content() {
                return this.product && this.product.content ? this.product.content.data : null;
            },

            description() {
                if (this.content) {
                    return this.content.thumbnail_description
                } else if (this.product && this.product.description) {
                    return this.product.description;
                } else {
                    return null
                }
            },

            funnelLink() {
                let link = {
                    name: 'member-cart',
                    params: {
                        productUuid: this.product.slug,
                        cartFunnelStep: 'product',
                        value: this.product
                    }
                }

                if (this.selectedItemId) {
                    link.query = {
                        selected_item_id: this.selectedItemId
                    }
                }

                return link;
            },

            isFree() {
                return this.product.plans_count === 0 && this.product.not_free_skus_count === 0;
            }
        },

        methods: {
            randomNumber(min, max) {
                return Math.round(Math.random() * (max - min) + min);
            },

            redirectToThriveCart(url) {
                window.open(url, '_blank');
                this.$emit('closeGlobalSearch');
            },

            getBackground(product) {
                if (product.background) {
                    return product.background.data.url;
                }
                const tagName = product.tagStyles && product.tagStyles.data && product.tagStyles.data[0]
                    ? product.tagStyles.data[0].title
                    : '';

                if (tagName.toLowerCase() === 'blues') {
                    return this.cardBg[1]
                } else if (tagName.toLowerCase() === 'funk') {
                    return this.cardBg[2]
                } else if (tagName.toLowerCase() === 'métal') {
                    return this.cardBg[3]
                } else if (tagName.toLowerCase() === 'rock') {
                    return this.cardBg[5]
                } else if (tagName.toLowerCase() === 'pop') {
                    return this.cardBg[4]
                } else if (tagName.toLowerCase() === 'variété française') {
                    return this.cardBg[6]
                }

                return this.cardBg[0]
            },

            getTagColor(tagName) {
                if (tagName.toLowerCase() === 'blues') {
                    return '#80FFD1'
                } else if (tagName.toLowerCase() === 'funk') {
                    return '#8FFF7F'
                } else if (tagName.toLowerCase() === 'métal') {
                    return '#FFE6E6'
                } else if (tagName.toLowerCase() === 'rock') {
                    return '#FFD47F'
                } else if (tagName.toLowerCase() === 'pop') {
                    return '#F9E9F9'
                } else if (tagName.toLowerCase() === 'général') {
                    return '#00529E'
                } else if (tagName.toLowerCase() === 'variété française') {
                    return '#E8E9F1'
                }

                return 'default lighten-5'
            },

            getTagTextStyle(tagName) {
                let style = 'color:#52575C';

                if (tagName.toLowerCase() === 'blues') {
                    return 'color:#212121'
                } else if (tagName.toLowerCase() === 'funk') {
                    return 'color:#212121'
                } else if (tagName.toLowerCase() === 'métal') {
                    return 'color:#FF0000'
                } else if (tagName.toLowerCase() === 'rock') {
                    return 'color:#212121'
                } else if (tagName.toLowerCase() === 'pop') {
                    return 'color:#BA22BA'
                } else if (tagName.toLowerCase() === 'général') {
                    return 'color:#FFFFFF'
                } else if (tagName.toLowerCase() === 'variété française') {
                    return 'color:#171A6B'
                }

                return style
            },
        },
    }
</script>
