<template>
    <v-card flat tile style="layout-padding-x box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);">
        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="justify-center">
            <v-col cols="12" md="3" lg="3">
                <v-text-field
                        style="border-radius: 48px;"
                        class="gray-input"
                        placeholder="Recherche par titre, artiste, guitariste..."
                        prepend-inner-icon="mdi-magnify"
                        :background-color="$vuetify.theme.dark === true ? 'secondary lighten-1' : ''"
                        solo dense clearable
                        hide-details
                        flat
                        v-model="search.text"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2">
                <v-select
                        color="primary"
                        :items="tagStylesAvailable"
                        item-text="title"
                        flat dense
                        item-value="id"
                        label="Styles"
                        hide-details
                        return-object
                        v-model="search.styles"
                        solo multiple
                        chips deletable-chips
                ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="2">
                    <v-autocomplete
                            color="primary"
                            :items="authorsAvailable"
                            item-text="name"
                            flat dense
                            item-value="id"
                            label="Artiste"
                            hide-details
                            return-object
                            v-model="search.author"
                            solo
                            chips deletable-chips
                    ></v-autocomplete>
                </v-col>
            <v-col cols="12" sm="4" md="2">
                <v-select
                        color="primary"
                        :items="difficulties"
                        item-text="title"
                        flat dense
                        item-value="id"
                        label="Niveau"
                        hide-details
                        return-object
                        v-model="search.difficulty"
                        solo
                        chips deletable-chips
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="">
                <v-select
                        color="primary"
                        :items="instrumentValues"
                        item-text="name"
                        flat dense
                        item-value="id"
                        label="Instruments"
                        :loading="instrumentLoading"
                        hide-details
                        return-object
                        v-model="search.instruments"
                        solo multiple
                ></v-select>
            </v-col>
            <v-col cols="1">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="resetFilters"
                               v-bind="attrs"
                               v-on="on"
                               depressed rounded small icon
                               class="">
                            <v-icon>mdi-filter-remove-outline</v-icon>
                        </v-btn>
                    </template>
                    <span>Retirer les filtres</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <div v-else-if="$vuetify.breakpoint.smAndDown">
            <v-col cols="12" md="2" lg="3">
                <v-text-field
                        style="border-radius: 48px;"
                        class="gray-input"
                        placeholder="Recherche par titre, artiste, guitariste..."
                        prepend-inner-icon="mdi-magnify"
                        :background-color="$vuetify.theme.dark === true ? 'secondary lighten-1' : ''"
                        solo dense clearable
                        hide-details
                        flat
                        v-model="search.text"
                ></v-text-field>
            </v-col>

            <div v-if="!displayMobileFilters" class="text-center">
                <v-icon small>mdi-arrow-down</v-icon>
                <v-btn small text @click="displayMobileFilters = true;">
                    Ouvrir les fitres
                </v-btn>
                <v-icon small>mdi-arrow-down</v-icon>
            </div>

            <div v-if="displayMobileFilters">
                <v-col cols="12" sm="4" md="2">
                    <v-select
                            color="primary"
                            :items="tagStylesAvailable"
                            item-text="title"
                            flat dense
                            item-value="id"
                            label="Styles"
                            hide-details
                            return-object
                            v-model="search.styles"
                            solo multiple
                            chips deletable-chips
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                    <v-select
                            color="primary"
                            :items="authorsAvailable"
                            item-text="name"
                            flat dense
                            item-value="id"
                            label="Artiste"
                            hide-details
                            return-object
                            v-model="search.author"
                            solo
                            chips deletable-chips
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2">
                    <v-select
                            color="primary"
                            :items="difficulties"
                            item-text="title"
                            flat dense
                            item-value="id"
                            label="Niveau"
                            hide-details
                            return-object
                            v-model="search.difficulty"
                            solo
                            chips deletable-chips
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="">
                    <v-select
                            color="primary"
                            :items="instrumentValues"
                            item-text="name"
                            flat dense
                            item-value="id"
                            label="Instruments"
                            :loading="instrumentLoading"
                            hide-details
                            return-object
                            v-model="search.instruments"
                            solo multiple
                    ></v-select>
                </v-col>
                <div>
                    <v-col cols="12" sm="12" md="2">
                        <v-checkbox
                                v-model="search.displayOnlyPaidSongs"
                                :false-value="0"
                                :true-value="1"
                                label="Afficher uniquement les chansons que j'ai acheté."
                                hide-details
                                class="mt-1"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn @click="resetFilters"
                                    v-bind="attrs"
                                    v-on="on"
                                    depressed rounded small icon
                                    class="">
                                    <v-icon>mdi-filter-remove-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Retirer les filtres</span>
                        </v-tooltip>
                    </v-col>
                </div>
                <div class="text-center">
                    <v-icon small>mdi-arrow-up</v-icon>
                    <v-btn small text @click="displayMobileFilters = false;">
                        Fermer les fitres
                    </v-btn>
                    <v-icon small>mdi-arrow-up</v-icon>
                </div>
            </div>
        </div>

    </v-card>
</template>

<script>
import Helpers from "mg-js-core/lib/services/Helpers";
import SongPartLogic from 'mg-js-core/lib/logics/SongPartLogic'
import TagLogic from 'mg-js-core/lib/logics/TagLogic'
import InstrumentLogic from 'mg-js-core/lib/logics/InstrumentLogic'
import SongLogic from 'mg-js-core/lib/logics/SongLogic'

    export default {
        name: 'songs-list-filters',

        data: () => ({
            Helpers,
            search: {
                text: null,
                styles: [],
                difficulty: null,
                favorites: null,
                instruments: [],
                author: null,
                displayOnlyPaidSongs: null,
            },
            difficulties: SongPartLogic.getTagDifficulty(),
            tagStylesAvailable: [],
            pagination: Helpers.initPagination(100, 1, 'created_at', 0),
            filters: {
                enabled: 1,
                search: '',
            },
            instrumentValues: [],
            instrumentLoading: false,
            displayMobileFilters: false,
            authorsAvailable: [],
        }),
        mounted() {
            if (this.$route.query.search) {
                this.search.text = this.$route.query.search;
            }
            this.getAllTagStyles();
            this.getAllInstruments();
            this.getAllAuthors();
        },
        computed: {

            ordersAvailable() {
                return [
                    {
                        id: 'asc',
                        title: 'Ordre alphabétique croissant'
                    },
                    {
                        id: 'desc',
                        title: 'Ordre alphabétique décroissant'
                    }
                ]
            }
        },

        methods: {
            resetFilters() {
                this.search.text = null;
                this.search.styles = [];
                this.search.difficulty = null;
                this.search.favorites = null;
                this.search.instruments = [];
                this.search.author = null;
                this.search.displayOnlyPaidSongs = null;
            },

            setQuery(name, value){
                let query = Object.assign({}, this.$route.query)

                if((value && value.length) || typeof value === 'number'){
                    query[name] = value;
                } else{
                    delete query[name]
                }

                this.$router.replace({ query });
            },
            getAllTagStyles() {
                TagLogic
                    .getAllStyles(Helpers.initPagination(20, 1, 'position', true))
                    .then(({data}) => {
                        this.tagStylesAvailable = data;

                        if (this.$route.query.styles) {
                            this.search.styles = this.tagStylesAvailable.filter(style => this.$route.query.styles.split(',').includes(style.id))
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                    })

            },
            getAllInstruments() {
                this.instrumentLoading = true;
                InstrumentLogic
                    .getAll(this.pagination, this.filters)
                    .then(({data}) => {
                        this.instrumentValues = data;
                        this.instrumentLoading = false;

                        if (this.$route.query.instruments) {
                            this.search.instruments = this.instrumentValues.filter(instrument => this.$route.query.instruments.split(',').includes(instrument.id))
                        }
                    })
                    .catch((err) => {
                        this.instrumentLoading = false; 
                        console.error(err)
                    })
            },
            getAllAuthors() {
                const forRepertory = this.$route.name === 'member-course-list';
                SongLogic
                    .getAllAuthors(forRepertory)
                    .then((data) => {
                        this.authorsAvailable = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            }
        },

        watch: {
            'search.text'(value) {
                if(this.$route.query.search !== value){
                    this.setQuery('search', value)
                }
            },
            'search.styles'(value) {
                let values = null;
                if (value) {
                    values = value.map(item => item.id).toString();
                }

                this.setQuery('styles', values)
            },
            'search.difficulty'(value) {
                let id = null;
                if(value && typeof value === 'object') {
                    id = value.id;
                }
                if(this.$route.query.difficulty !== id){
                    this.setQuery('difficulty', id)
                }
            },
            'search.favorites'(value) {
                if(this.$route.query.favorites !== value){
                    this.setQuery('favorites', value)
                }
            },
            'search.instruments'(value) {
                let values = null;
                if (value) {
                    values = value.map(item => item.id).toString();
                }
                this.setQuery('instruments', values)
            },
            'search.author'(value) {
                let id = null;
                if(value && typeof value === 'object') {
                    id = value.id;
                }
                if(this.$route.query.author !== id){
                    this.setQuery('author', id)
                }
            },
            'search.displayOnlyPaidSongs'(value) {
                if(this.$route.query.displayOnlyPaidSongs !== value){
                    this.setQuery('displayOnlyPaidSongs', value)
                }
            },
        },
    }
</script>
