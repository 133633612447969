<template>
    <div>
        <v-btn class="px-5"
            v-if="$vuetify.breakpoint.mdAndDown && !showFilters"
            @click="showFilters = true;"
            depressed color="primary" small
            rounded>
            Afficher les filtres
        </v-btn>
        <v-card v-if="showFilters" flat tile style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);">
            <v-row>
                <v-col cols="12" sm="6" md="2" v-if="this.displayFilters.types">
                    <v-select
                            color="default"
                            :items="productTypeValues"
                            label="Type de cours"
                            item-text="title"
                            item-value="id"
                            flat dense
                            hide-details
                            chips deletable-chips
                            v-model="search.types"
                            solo multiple
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="this.displayFilters.styles">
                    <v-select
                            color="default"
                            :items="tagStyleValues"
                            item-text="title"
                            flat dense
                            item-value="id"
                            label="Parcours"
                            hide-details
                            v-model="search.styles"
                            solo multiple
                            chips deletable-chips
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="" v-if="this.displayFilters.instruments">
                    <v-select
                            color="primary"
                            :items="instrumentValues"
                            item-text="name"
                            flat dense
                            item-value="id"
                            label="Instruments"
                            :loading="instrumentLoading"
                            hide-details
                            return-object
                            v-model="search.instruments"
                            solo multiple
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <v-btn @click="resetFilters"
                        depressed rounded small
                        color="primary"
                        class="">Retirer les filtres
                    </v-btn>
                    <!-- <v-btn @click="doSearch"
                            depressed rounded small
                            color="primary"
                            class="">Rechercher
                    </v-btn> -->
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="3" v-if="this.displayFilters.from_date">
                    <mg-datepicker
                            label="Publiée après le"
                            v-model="search.from_date"
                            :dark="$vuetify.theme.dark"
                            :max="new Date().toISOString().substr(0, 10)"
                            :openYear="true"
                            data-vv-name="search.from_date">
                    </mg-datepicker>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2" class="" v-if="this.displayFilters.is_active">
                    <v-checkbox
                        v-model="search.is_active"
                        label="Afficher uniquement les vidéos des membres actifs"
                    ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="2" lg="2" class="" v-if="this.displayFilters.is_friend">
                    <v-checkbox
                        v-model="search.is_friend"
                        label="Afficher uniquement les vidéos de mes amis"
                    ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6" md="2" lg="2" class="" v-if="this.displayFilters.is_not_viewed">
                    <v-checkbox
                        v-model="search.is_not_viewed"
                        label="Afficher uniquement les vidéos que je n'ai pas vues"
                    ></v-checkbox>
                </v-col>
            </v-row>

            <v-btn class="px-5"
                v-if="$vuetify.breakpoint.mdAndDown && showFilters"
                @click="showFilters = false;"
                depressed color="primary" small
                rounded>
                Cacher les filtres
            </v-btn>
        </v-card>
    </div>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";
    import moment from 'moment';
    import PageStore from 'mg-js-core/lib/stores/PageStore';
    import ProductFilterStore from 'mg-js-core/lib/stores/ProductFilterStore';
    import InstrumentLogic from 'mg-js-core/lib/logics/InstrumentLogic';
    import TagLogic from "mg-js-core/lib/logics/TagLogic";
    import ProductTypeLogic from "mg-js-core/lib/logics/ProductTypeLogic";

    export default {
        name: 'member-video-list-filters',

        props: {
            displayFilters: {
                
                default: {
                    'styles' : true,
                    'types' : true,
                    'instruments' : true,
                    'from_date' : true,
                    'is_friend' : true,
                    'is_active' : true,
                    'is_not_viewed' : true,
                }
            }
        },

        data: () => ({
            Helpers,
            PageStore,
            ProductFilterStore,
            pagination: Helpers.initPagination(100, 1, 'created_at', 0),
            filters: {
            },
            search: {
            },
            instrumentValues: [],
            productTypeValues: [],
            tagStyleValues: [],
            instrumentLoading: false,
            showFilters: false,
        }),

        mounted() {
            //this.resetFilters();
            this.getAllInstruments();
            this.getAllProductTypes();
            this.getAllTagStyles();

            if (this.$vuetify.breakpoint.mdAndUp) {
                this.showFilters = true;
            }
        },

        computed: {
            // search : {
            //     get(){
            //         return this.ProductFilterStore.search;
            //     },

            //     set(value){
            //         this.ProductFilterStore.search = value
            //     }
            // },

            // productTypesAvailable : {
            //     get(){
            //         return this.ProductFilterStore.productTypesAvailable;
            //     },

            //     set(value){
            //         this.ProductFilterStore.productTypesAvailable = value
            //     }
            // },

            // tagStylesAvailable : {
            //     get(){
            //         return this.ProductFilterStore.tagStylesAvailable;
            //     },

            //     set(value){
            //         this.ProductFilterStore.tagStylesAvailable = value
            //     }
            // },
        },

        methods: {
            resetFilters() {
                // this.search.text = "";
                this.search.types = [];
                this.search.styles = [];
                this.search.instruments = [];
                this.search.from_date = null;
                this.search.is_active = null;
                this.search.is_friend = null;
                this.search.is_not_viewed = null;
            },
            getAllInstruments() {
                //this.instrumentLoading = true;
                InstrumentLogic
                    .getAll(this.pagination, this.filters)
                    .then(({data}) => {
                        this.instrumentValues = data;
                        //this.instrumentLoading = false;
                    })
                    .catch((err) => {
                        //this.instrumentLoading = false; 
                        console.error(err)
                    })
            },
            getAllTagStyles() {
                TagLogic
                    .getAllStyles(this.pagination, this.filters)
                    .then(({data}) => {
                        this.tagStyleValues = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            },
            getAllProductTypes() {
                ProductTypeLogic
                    .getMeAll(this.pagination, this.filters)
                    .then(({data}) => {
                        this.productTypeValues = data;
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            },
            setQuery(name, value){
                let query = Object.assign({}, this.$route.query)

                if((value && value.length) || typeof value === 'number' || typeof value === 'boolean'){
                    query[name] = value;
                } else{
                    delete query[name]
                }

                this.$router.replace({ query });
            },
        },

        watch: {
            'search.types'(value) {
                //this.ProductFilterStore.filterProducts();
                this.setQuery('types', value);
            },
            'search.text'(value) {
                //this.ProductFilterStore.filterProducts();
                this.setQuery('search', value);
            },
            'search.styles'(value) {
                //this.ProductFilterStore.filterProducts();

                if(this.$route.query.styles !== value){
                    this.setQuery('styles', value)
                }
            },
            'search.instruments'(value) {
                //this.ProductFilterStore.filterProducts();

                let values = null;
                if (value) {
                    values = value.map(item => item.id).toString();
                }

                if(this.$route.query.instruments !== values){
                    this.setQuery('instruments', values)
                }
            },
            'search.is_active'(value) {
                if (!value) {
                    this.setQuery('is_active', null);
                } else {
                    this.setQuery('is_active', value);
                }
            },
            'search.is_friend'(value) {
                if (!value) {
                    this.setQuery('is_friend', null);
                } else {
                    this.setQuery('is_friend', value);
                }
            },
            'search.is_not_viewed'(value) {
                if (!value) {
                    this.setQuery('is_not_viewed', null);
                } else {
                    this.setQuery('is_not_viewed', value);
                }
            },
            'search.from_date'(value) {
                if (!value) {
                    this.setQuery('from_date', null);
                } else {
                    this.setQuery('from_date', moment(value).format('YYYY-MM-DD'));
                }
            },
        }
    }
</script>
