<template>
    <button @click="goToClubOffers()" :class="`club-tier-${tier[tier.length - 1]}-badge text-caption`">
        <span v-if="tier.includes('COMPLETE')">
            🔥 Club Complet 🔥
        </span>
        <span v-else-if="tier.includes('VIP')">
            Club VIP 🔥
        </span>
        <span v-else-if="tier.includes('REPERTORY')">
            Club Répertoire
        </span>
        <span v-else-if="tier.includes('PRIVILEGE')">
            Club Privilèges
        </span>
    </button>
</template>

<script>
export default {
    name: 'ClubBadge',
    props: ['tier', 'clickable'],
    methods: {
        goToClubOffers() {
            if (this.clickable){
                this.$router.push({name: 'member-club-offers'})
            }
        }
    }
}
</script>

<style scoped>
.club-tier-PRIVILEGE-badge {
    background: #43CC76;
    color: #FFF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px;
    border-radius: 9px;
}
.club-tier-REPERTORY-badge {
    background: #43CC76;
    color: #FFF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px;
    border-radius: 9px;
}
.club-tier-VIP-badge {
    background: #000;
    color: #FFF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px;
    border-radius: 9px;
}
.club-tier-COMPLETE-badge {
    background: #01366e;
    color: #FFF;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px;
    border-radius: 9px;
}
</style>
