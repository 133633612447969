import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './scss/main.scss';
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm';
import VueObserveVisibility from 'vue-observe-visibility'

Vue.config.productionTip = false

import MgJsCore from "mg-js-core/src/member-main";

import store from '../src/stores'
import MgTextField from "./components/MgTextField";
import MgSegmentControl from "./components/MgSegmentControl";
import frMessages from "@/plugins/Translation/messages/fr.js";
import enMessages from "@/plugins/Translation/messages/en.js";
import Lang from "lang.js";
import PlatformStore from "mg-js-core/lib/stores/PlatformStore";

Vue.use(VueObserveVisibility)

Vue.use(MgJsCore, {
    locale: process.env.VUE_APP_LOCALE,
    localeFallback: process.env.VUE_APP_FALLBACK_LOCALE,
    googleMapsKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    store
});

Vue.use(VueMeta, {
    refreshOnceOnNavigation : true
})

Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID, // Your GTM ID
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
});

Vue.component('mg-text-field', MgTextField);
Vue.component('mg-segment-control', MgSegmentControl);

Vue.prototype.$trans = new Lang({
    locale: 'fr',
    fallback: 'en',
    messages: {
        ...frMessages,
        ...enMessages
    }
})

Vue.prototype.$platformStore = PlatformStore

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
