<template>
    <router-link :to="to">
        <svg v-if="!isFocusDisplay" xmlns="http://www.w3.org/2000/svg"
             :width="width"
             :height="height"
             viewBox="0 0 138 48">
            <path d="M73.0886 26.8268H67.1996C66.0953 26.8221 65.038 26.3893 64.2591 25.6234C63.4801 24.8575 63.0431 23.8208 63.0437 22.7403V13.5844C63.0437 11.532 62.0968 10.4646 60.4316 10.4646C58.7664 10.4646 57.7372 11.532 57.7372 13.5844V22.1582H53.2952V13.5844C53.2952 11.532 52.3689 10.4646 50.6831 10.4646C49.0364 10.4646 48.0072 11.532 48.0072 13.5844V22.1582H43.5447V6.82316H48.001V8.99633C49.0302 7.70733 50.3661 6.54119 52.6283 6.54119C54.664 6.54119 56.2284 7.40724 57.0291 8.95807C58.4062 7.38911 60.0529 6.54119 62.1915 6.54119C62.9481 6.53034 63.7005 6.65315 64.4125 6.90372C66.3659 7.60864 67.4774 9.39914 67.4774 12.1564V22.5409C67.4826 22.7209 67.558 22.8922 67.6882 23.0195C67.8183 23.1468 67.9933 23.2206 68.1773 23.2257H71.2607L71.4048 23.5882C71.8122 24.738 72.3787 25.8277 73.0886 26.8268Z"
                  :fill="color"/>
            <path d="M72.9592 29.1027V37.6565C72.9592 39.7289 71.8888 40.7763 70.1824 40.7763C68.476 40.7763 67.488 39.7289 67.488 37.6565V29.1027H63.044V39.0442C63.044 42.5668 64.9974 44.7399 68.3711 44.7399C70.6126 44.7399 71.93 43.5516 72.9571 42.2646V44.4378H77.4012V29.1027H72.9592Z"
                  :fill="color"/>
            <path d="M79.5622 29.1027V44.4378H84.0042V29.1027H79.5622Z" :fill="color"/>
            <path d="M96.1673 32.8247V29.1027H92.4787V25.1773H88.0141V29.1027H86.143V32.8268H88.0141V40.0915C88.0141 43.6544 89.8666 44.6997 92.5796 44.6997C94.0801 44.6997 95.1711 44.3573 96.0973 43.8135V40.3312C95.4229 40.6922 94.6657 40.8794 93.8969 40.875C92.9089 40.875 92.4787 40.3714 92.4787 39.3866V32.8247H96.1673Z"
                  :fill="color"/>
            <path d="M104.368 28.9195C101.692 28.9195 100.008 29.4028 98.2793 30.148L99.3908 33.4894C100.832 32.9657 102.023 32.6435 103.713 32.6435C105.955 32.6435 107.087 33.6505 107.087 35.4632V35.7049C105.857 35.2848 104.563 35.0737 103.261 35.0805C99.539 35.0805 96.9249 36.6293 96.9249 39.9706V40.0311C96.9249 43.0703 99.3517 44.7399 102.314 44.7399C104.473 44.7399 105.976 43.9545 107.064 42.7883V44.4378H111.364V35.5417C111.36 31.3968 109.222 28.9195 104.368 28.9195ZM107.145 39.0422C107.145 40.6111 105.747 41.7189 103.668 41.7189C102.227 41.7189 101.221 41.0341 101.221 39.8679V39.8075C101.221 38.4379 102.394 37.6948 104.308 37.6948C105.284 37.6861 106.251 37.8782 107.147 38.2587L107.145 39.0422Z"
                  :fill="color"/>
            <path d="M117.613 32.1822V29.1027H113.149V44.4378H117.613V38.7884C117.613 35.1248 119.423 33.3746 122.385 33.3746H122.611V28.8208C119.978 28.6999 118.519 30.0675 117.613 32.1822Z"
                  :fill="color"/>
            <path d="M130.365 28.8208C125.8 28.8208 122.611 32.424 122.611 36.8106V36.8509C122.611 41.5598 126.088 44.7802 130.797 44.7802C133.636 44.7802 135.754 43.6926 137.215 41.9223L134.659 39.729C133.424 40.8367 132.356 41.2979 130.855 41.2979C128.86 41.2979 127.461 40.2708 127.029 38.299H137.938C137.98 37.9312 138.001 37.5613 138 37.1913V37.151C137.998 32.7845 135.612 28.8208 130.365 28.8208ZM126.971 35.5659C127.321 33.5941 128.513 32.3051 130.365 32.3051C132.218 32.3051 133.41 33.6344 133.677 35.5659H126.971Z"
                  :fill="color"/>
            <path d="M52.2456 33.1873V37.0724H56.8522V39.9968C55.6995 40.8226 54.199 41.2254 52.5296 41.2254C48.8245 41.2254 46.2145 38.4883 46.2145 34.7441V34.6837C46.2145 31.2215 48.8678 28.3233 52.2003 28.3233C54.6271 28.3233 56.0453 29.0886 57.6509 30.3958L60.5573 26.988C58.3775 25.1753 56.1153 24.1683 52.3506 24.1683C46.0354 24.1683 41.4082 28.9376 41.4082 34.7341V34.7945C41.4082 40.8367 45.8914 45.2999 52.3917 45.2999C55.6643 45.3142 58.8304 44.1622 61.2983 42.0592V33.1873H52.2456Z"
                  :fill="color"/>
            <path d="M79.4142 23.2197H83.7677V26.8248H78.8502C76.1887 26.8248 74.9023 25.6527 73.7043 22.5309L67.6753 6.82115H72.3025L75.7215 17.2721L79.2887 6.81713H84.0044L77.8622 22.2187C78.2409 22.9941 78.6464 23.2197 79.4142 23.2197Z"
                  :fill="color"/>
            <path d="M18.3196 3.46191C5.16447 3.46191 0 8.96434 0 14.0176C0 30.4645 14.1761 45.3001 18.3196 45.3001C22.4631 45.3001 36.6392 30.4625 36.6392 14.0176C36.6433 8.96434 31.4809 3.46191 18.3196 3.46191ZM26.0838 32.3658C23.0477 36.9498 19.8407 39.84 18.3196 40.7624C16.7984 39.8379 13.5853 36.9498 10.5554 32.3658C8.26233 28.9056 4.41728 21.9208 4.41728 14.0176C4.41728 11.1093 8.53404 7.99759 17.4839 7.81028V12.0821C16.9045 12.2543 16.3969 12.604 16.036 13.0798C15.675 13.5555 15.4798 14.1321 15.479 14.7246V20.5754C15.4793 21.1683 15.6744 21.7453 16.0354 22.2215C16.3963 22.6976 16.9041 23.0476 17.4839 23.2199V28.0174H19.1573V23.2199C19.7371 23.0476 20.2449 22.6976 20.6059 22.2215C20.9668 21.7453 21.1619 21.1683 21.1622 20.5754V14.7246C21.1619 14.1317 20.9668 13.5547 20.6059 13.0785C20.2449 12.6024 19.7371 12.2524 19.1573 12.0801V7.81028C28.1113 7.99759 32.2239 11.1093 32.2239 14.0176C32.2219 21.9208 28.3768 28.9056 26.0838 32.3658Z"
                  :fill="color"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg"
             :width="width"
             :height="height"
             viewBox="0 0 138 48">
            <path d="M18.3196 3.46191C5.16447 3.46191 0 8.96434 0 14.0176C0 30.4645 14.1761 45.3001 18.3196 45.3001C22.4631 45.3001 36.6392 30.4625 36.6392 14.0176C36.6433 8.96434 31.4809 3.46191 18.3196 3.46191ZM26.0838 32.3658C23.0477 36.9498 19.8407 39.84 18.3196 40.7624C16.7984 39.8379 13.5853 36.9498 10.5554 32.3658C8.26233 28.9056 4.41728 21.9208 4.41728 14.0176C4.41728 11.1093 8.53404 7.99759 17.4839 7.81028V12.0821C16.9045 12.2543 16.3969 12.604 16.036 13.0798C15.675 13.5555 15.4798 14.1321 15.479 14.7246V20.5754C15.4793 21.1683 15.6744 21.7453 16.0354 22.2215C16.3963 22.6976 16.9041 23.0476 17.4839 23.2199V28.0174H19.1573V23.2199C19.7371 23.0476 20.2449 22.6976 20.6059 22.2215C20.9668 21.7453 21.1619 21.1683 21.1622 20.5754V14.7246C21.1619 14.1317 20.9668 13.5547 20.6059 13.0785C20.2449 12.6024 19.7371 12.2524 19.1573 12.0801V7.81028C28.1113 7.99759 32.2239 11.1093 32.2239 14.0176C32.2219 21.9208 28.3768 28.9056 26.0838 32.3658Z"
                  :fill="color"/>
        </svg>
    </router-link>
</template>

<script>
    export default {
        name: 'mg-logo-icon',

        props: {
            dark: Boolean,

            width: {
                type: [Number, String],
                default: 133
            },
            height: {
                type: [Number, String],
                default: 48
            },
            to : {
                type : Object,
                default() {
                    return {name : 'member-dashboard'}
                }
            }
        },
        data: () => ({
            colorLight: "#F3F9FF",
            colorDark: "#25282B"
        }),

        computed : {
            color(){
                return this.dark ? this.colorDark : this.colorLight;
            },
            isFocusDisplay() {
                return this.$vuetify.breakpoint.mdAndUp && (this.$route.name === 'member-lesson-show' || this.$route.name === 'repertory-lesson');
            }
        },

        mounted() {

        },
    }
</script>
