<template>
    <router-view/>
</template>

<script>
    import {EventBus} from 'mg-js-core/lib/services/Request'
    import AuthLogic from "mg-js-core/lib/logics/AuthLogic";
    import _ from 'lodash';

    export default {
        name: 'App',

        beforeMount() {
            this.loadRecaptchaScript()
        },

        mounted() {
            EventBus.$on('401-unauthorized', () => {
                this.redirectToRegister()
            });

            EventBus.$on('401-need-information', () => {
                this.redirectToInformation()
            });

            EventBus.$on('401-force-reset-password', () => {
                this.redirectToForceResetPassword()
            });

            EventBus.$on('401-need-email-verification', () => {
                this.redirectToNeedEmailVerification()
            });

            EventBus.$on('logout', () => {
                this.$vuetify.theme.dark = false;
            });

            const accessToken = AuthLogic.getTokens()

            if (accessToken && Object.prototype.hasOwnProperty.call(accessToken, "access_token")) {
                AuthLogic.loadMe()
            }
        },

        methods: {
            loadRecaptchaScript() {
                if (process.env.VUE_APP_RECAPTCHA_ENABLED === 'true') {
                    var script = document.createElement('script');
                    script.id = '__RECAPTCHA_SCRIPT';
                    script.src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit";
                    script.async = true;
                    script.defer = true;
                    document.head.appendChild(script);
                }
            },

            redirectToRegister: _.debounce(function () {
                if (this.$route.name !== 'register') {
                    this.$router.push({name: 'register', query: {redirect: this.$route.fullPath}})
                }
            }, 300),
            redirectToInformation: _.debounce(function () {
                if (this.$route.name !== 'information' && this.$route.name !== 'information-ressource') {
                    this.$router.push({name: 'information', query: {redirect: this.$route.fullPath}})
                }
            }, 300),
            redirectToForceResetPassword: _.debounce(function () {
                if (this.$route.name !== 'force-reset-password') {
                    this.$router.push({name: 'force-reset-password', query: {redirect: this.$route.query.redirect ?? this.$route.fullPath}})
                }
            }, 300),
            redirectToNeedEmailVerification: _.debounce(function() {
                if (this.$route.name !== 'need-email-verification') {
                    this.$router.push({name: 'need-email-verification', query: {redirect: this.$route.fullPath}})
                }
            }, 300),
        }
    }
</script>
