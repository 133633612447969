import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import fr from 'vuetify/es5/locale/fr'

fr.dataTable.sortBy = 'Trier par';

Vue.use(Vuetify)

const MY_ICONS = {
    'mg-home': 'icon-mghome',
    'mg-all-courses': 'icon-mgallcourses-1',
    'mg-chat': 'icon-mgchat',
    'mg-instructor': 'icon-mginstructor',
    'mg-my-learning': 'icon-mgmylearning',
    'mg-my-progress': 'icon-mgmyprogress',
    'mg-support': 'icon-mgsupport',
    'mg-trending-up': 'icon-trending-up',
    'mg-play-circle': 'icon-mg-play-circle',
    'mg-chevron-down': 'icon-mg-chevron-down',
    'mg-clock-outline': 'icon-mg-clock-outline',
    'mg-checked': 'icon-mg-checked',
    'mg-minus': 'icon-mg-minus',
    'mg-arrow-right': 'icon-mg-arrow-right',
    'mg-arrow-left': 'icon-mg-arrow-left',
    'mg-learning': 'icon-mg-learning-1',
    'mg-pause-watching': 'icon-mg-pause-watching',
    'mg-play': 'icon-mg-play',
    'mg-mastered': 'icon-mgmasteredicon',
    'mg-review-need': 'icon-mgreviewneedicon',
    'mg-video': 'icon-mgvideo',
    'mg-send': 'icon-mg-send',
    'mg-reply': 'icon-mg-reply',
};

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        // dark : true,
        themes: {
            light: {
                default: {
                    base: '#25282B',     // gray-6
                    lighten5: '#F2F2F2', // gray-1
                    lighten4: '#E8E8E8', // gray-2
                    lighten3: '#CACCCF', // gray-3
                    lighten2: '#A0A4A8', // gray-4
                    lighten1: '#52575C', // gray-5
                },
                primary: {
                    base: '#0075E2',     // blue-6
                    lighten5: '#F3F9FF', //blue-1
                    lighten4: '#D1E9FF', // blue-2
                    lighten3: '#8DC8FF', // blue-3
                    lighten2: '#5AAFFF', // blue-4
                    lighten1: '#2797FF', // blue-5
                    darken1: '#00529E',  // blue-7
                    darken2: '#00376B',  // blue-8
                    darken3: '#002145',  // blue-9
                    darken4: '#000912',
                },
                secondary: '#002145',
                accent: '#8DC8FF',
                creamy: '#E5E5E5',
                success: '#2AC769',
                warning: '#FFB300',
                star: '#f3b842',
                danger: '#f24921',
            },
            dark: {
                default: '#fff', // #E53935
                primary: '#5aafff',
                secondary: '#29333d',
                accent: '#8DC8FF',
                creamy: '#636363',
                success: '#2AC769',
                warning: '#FFB300',
                gray: '#fff',
                star: '#f3b842',
                'blue-1': '#5a7086',
                danger: '#f24921',
            }
        }
    },
    iconfont: 'mdi',
    icons: {
        values: MY_ICONS,
    },
    lang: {
        locales: {fr},
        current: 'fr'
    },
});
