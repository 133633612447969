<template>
    <div class="btn-group text-body-1">
        <template v-for="(item,index) in items">
            <div :key="index"
                 @click="productTab = index"
                 :class="{'active' : productTab === index, 'border-right' : productTab > index + 1, 'border-left' : productTab <= index - 2 }">
                {{ item.name }}
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    .btn-group {
        display: inline-flex;
        border-radius: 8px;
        box-shadow: inset 0 0 0 2px var(--v-primary-base);

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 32px;
            cursor: pointer;

            color: var(--v-default-base);
            height: 40px;

            &.border-left:not(.active) {
                box-sizing: border-box;
                box-shadow: -2px 0 0 0 var(--v-primary-base);
            }

            &.border-right:not(.active) {
                box-shadow: 2px 0 0 0 var(--v-primary-base);
            }

            &.active {
                border-radius: 8px;
                background-color: var(--v-primary-base);
                color: #fff;
            }
        }
    }
</style>

<script>
    export default {
        name: 'mg-segment-control',

        props: {
            value: {
                type: Number,
                default: 0
            },
            items: Array
        },

        computed: {
            productTab: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
        }
    };
</script>
