const isMyPiano = !!process.env.VUE_APP_PLATFORM_CURRENT
    && process.env.VUE_APP_PLATFORM_CURRENT === process.env.VUE_APP_PLATFORM_MPP;

export default {
    'en.brand': {
        "customer_name": isMyPiano
            ? "MyPianiste|MyPianistes"
            : "MyGuitariste|MyGuitaristes",
        "name": isMyPiano
            ? "MyPianoPop"
            : "MyGuitare",
    }
}
