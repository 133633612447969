<template>
    <v-card flat tile style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);">
        <v-row>
            <v-col cols="12" sm="6" md="2" class="">
                <v-text-field
                        style="border-radius: 48px;"
                        class="gray-input"
                        placeholder="Recherche"
                        prepend-inner-icon="mdi-magnify"
                        :background-color="$vuetify.theme.dark === true ? 'secondary lighten-1' : ''"
                        solo dense clearable
                        hide-details
                        flat
                        v-model="search.text"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="">
                <v-select
                        color="primary"
                        :items="tagStylesAvailable"
                        item-text="title"
                        flat dense
                        item-value="id"
                        label="Parcours"
                        hide-details
                        return-object
                        v-model="search.styles"
                        solo multiple
                        chips deletable-chips
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2">
                <v-select
                        color="default"
                        :items="productTypesAvailable"
                        label="Type de cours"
                        item-text="title"
                        item-value="key"
                        flat dense
                        hide-details
                        chips deletable-chips
                        v-model="search.type"
                        solo
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="">
                <v-select
                        color="primary"
                        :items="instrumentValues"
                        item-text="name"
                        flat dense
                        item-value="id"
                        label="Instruments"
                        :loading="instrumentLoading"
                        hide-details
                        return-object
                        v-model="search.instruments"
                        solo multiple
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="">
                <v-select
                        color="primary"
                        :items="ordersAvailable"
                        item-text="title"
                        flat dense
                        item-value="id"
                        label="Tri"
                        hide-details
                        return-object
                        v-model="search.order"
                        solo
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2" class=" ">
              <v-btn @click="resetFilters"
                     depressed rounded small
                     color="primary"
                     class="">Retirer les filtres
              </v-btn>
            </v-col>
        </v-row>

    </v-card>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";
    import PageStore from 'mg-js-core/lib/stores/PageStore'
    import CourseFilterStore from "mg-js-core/lib/stores/CourseFilterStore";
    import InstrumentLogic from 'mg-js-core/lib/logics/InstrumentLogic'

    export default {
        name: 'member-course-list-filters',

        data: () => ({
            Helpers,
            PageStore,
            CourseFilterStore,
            pagination: Helpers.initPagination(100, 1, 'created_at', 0),
            filters: {
                enabled: 1,
                search: '',
            },
            instrumentValues: [],
            instrumentLoading: false,
        }),

        mounted() {
            this.getAllInstruments();
        },

        computed: {
            search : {
                get(){
                    return this.CourseFilterStore.search;
                },

                set(value){
                    this.CourseFilterStore.search = value
                }
            },
            
            productTypesAvailable : {
                get(){
                    return this.CourseFilterStore.productTypesAvailable;
                },

                set(value){
                    this.CourseFilterStore.productTypesAvailable = value
                }
            },

            tagStylesAvailable : {
                get(){
                    return this.CourseFilterStore.tagStylesAvailable;
                },

                set(value){
                    this.CourseFilterStore.tagStylesAvailable = value
                }
            },

            ordersAvailable() {
                return [
                    {
                        id: 'created_at',
                        title: 'Date accès'
                    },
                    {
                        id: 'alpha',
                        title: 'Ordre alphabétique'
                    }
                ]
            }
        },

        methods: {
            resetFilters() {
                this.search.text = "";
                this.search.styles = [];
                this.search.order = null;
                this.search.instruments = [];
                this.search.type = null;
            },

            setQuery(name, value){
                let query = Object.assign({}, this.$route.query)

                if(value && value.length){
                    query[name] = value;
                } else{
                    delete query[name]
                }

                this.$router.replace({ query });
            },
            getAllInstruments() {
                this.instrumentLoading = true;
                InstrumentLogic
                    .getAll(this.pagination, this.filters)
                    .then(({data}) => {
                        this.instrumentValues = data;
                        this.instrumentLoading = false;
                    })
                    .catch((err) => {
                        this.instrumentLoading = false; 
                        console.error(err)
                    })
            },
        },

        watch: {
            'search.text'(value) {
                this.CourseFilterStore.filterCourses()

                if(this.$route.query.search !== value){
                    this.setQuery('search', value)
                }
            },
            'search.styles'(value) {
                this.CourseFilterStore.filterCourses()
                const values = value.map(item => item.title.toLowerCase()).toString();

                if(this.$route.query.styles !== values){
                    this.setQuery('styles', values)
                }
            },
            'search.order'(value) {
                this.CourseFilterStore.filterCourses()

                if(this.$route.query.order !== value.id){
                    this.setQuery('order', value.id)
                }
            },
            'search.instruments'(value) {
                this.CourseFilterStore.filterCourses()
                const values = value.map(item => item.id).toString();

                if(this.$route.query.styles !== values){
                    this.setQuery('instruments', values)
                }
            },
        }
    }
</script>
