<template>
    <svg :width="width"
         :height="height"
         viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M8.66667 1.33337H26.6667C27.403 1.33337 28 1.93033 28 2.66671V22.6667V29.3334C28 30.0698 27.403 30.6667 26.6667 30.6667H8.66667C6.08934 30.6667 4 28.5774 4 26V6.00004C4 3.42271 6.08934 1.33337 8.66667 1.33337ZM6.66667 21.7825V6.00004C6.66667 4.89547 7.5621 4.00004 8.66667 4.00004H25.3333V21.3334H8.66667C7.95094 21.3334 7.27284 21.4945 6.66667 21.7825ZM25.3333 24H8.66667C7.5621 24 6.66667 24.8955 6.66667 26C6.66667 27.1046 7.5621 28 8.66667 28H25.3333V24Z"
              fill="#8DC8FF"/>
    </svg>
</template>

<script>
export default {
    name: 'mg-all-courses-icon',

    props: {
        width: {
            type: [Number, String],
            default: 32
        },
        height: {
            type: [Number, String],
            default: 32
        },
    },
}
</script>
