<template>
    <router-link :to="to">
        <svg v-if="!isFocusDisplay" :width="width" viewBox="0 0 1000 259" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M401.099 129.121H365.5C351.71 129.121 340.393 118.714 340.393 105.882V53.801C340.393 42.128 334.663 36.0584 324.602 36.0584C314.528 36.0584 308.3 42.128 308.3 53.801V102.57H281.467V53.801C281.467 42.128 275.854 36.0584 265.675 36.0584C255.719 36.0584 249.492 42.128 249.492 53.801V102.57H222.527V15.3419H249.492V27.6966C255.719 20.39 263.792 13.7362 277.476 13.7362C289.775 13.7362 299.234 18.6627 304.074 27.481C312.408 18.5459 322.364 13.7362 335.291 13.7362C340.262 13.7362 344.75 14.424 348.714 15.7972C360.541 19.8106 367.239 30.0021 367.239 45.6705V104.753C367.239 106.8 369.241 108.631 371.479 108.631H390.031L390.907 110.7C393.524 117.109 396.624 123.517 401.099 129.121"
                :fill="color"/>
            <path
                d="M439.231 108.307C434.59 108.307 432.126 107.015 429.832 102.545L467.033 13.7362H438.47L416.868 73.9932L396.157 13.7362H368.132L404.638 104.338C411.9 122.328 419.699 129.098 435.809 129.098L461.816 129.121V108.188L439.231 108.307"
                :fill="color"/>
            <path
                d="M102.704 219.349C134.815 219.349 159.528 219.349 159.528 219.349C164.196 219.349 167.995 215.792 167.995 211.45V33.0471C167.995 28.6993 164.196 25.1517 159.528 25.1517H35.5398C30.8582 25.1517 27.0471 28.6993 27.0471 33.0471V211.45C27.0471 215.792 30.8582 219.349 35.5398 219.349C35.5398 219.349 63.1809 219.349 92.5097 219.349V133.304C90.9009 133.304 91.0326 133.304 91.0326 133.304C84.9664 133.304 80.0474 128.727 80.0474 123.08V35.353C80.0474 29.7056 84.9664 25.1284 91.0326 25.1284H104.036C110.089 25.1284 115.008 29.7056 115.008 35.353V123.08C115.008 128.727 110.089 133.304 104.036 133.304C104.036 133.304 105.394 133.304 102.704 133.304V219.349ZM159.528 244.505H35.5398C15.93 244.505 0 229.67 0 211.45V33.0471C0 14.8227 15.93 -0.000126967 35.5398 -0.000126967H159.528C179.111 -0.000126967 195.055 14.8227 195.055 33.0471V211.45C195.055 229.67 179.111 244.505 159.528 244.505Z"
                :fill="color"/>
            <path d="M340.659 142.857H365.385V230.769H340.659V142.857Z" :fill="color"/>
            <path
                d="M435.864 200.839V196.397C431.042 194.432 425.16 193.116 418.563 193.116C406.971 193.116 399.831 197.386 399.831 205.274V205.592C399.831 212.341 405.898 216.277 414.642 216.277C427.479 216.277 435.864 210.027 435.864 200.839V200.839ZM373.626 206.574V206.262C373.626 187.194 389.484 178.161 412.138 178.161C421.941 178.161 428.539 179.648 435.507 181.785V180.303C435.507 169.798 428.538 164.219 414.999 164.219C404.467 164.219 397.34 165.862 388.61 168.973L381.827 149.924C392.346 145.812 402.692 142.857 418.921 142.857C433.904 142.857 444.237 146.31 451.192 152.717C458.332 159.294 461.538 168.973 461.538 180.802V231.873H435.321V222.341C428.724 229.09 419.808 233.517 406.441 233.517C388.424 233.517 373.626 224.165 373.626 206.574"
                :fill="color"/>
            <path
                d="M475.275 141.77H501.648V154.393C507.704 146.918 515.68 140.11 528.873 140.11C548.985 140.11 560.44 152.573 560.44 173.16V230.769H534.065V181.126C534.065 169.176 528.358 163.03 518.115 163.03C507.885 163.03 501.648 169.176 501.648 181.126V230.769H475.275V141.77"
                :fill="color"/>
            <path
                d="M646.082 187.16V186.816C646.082 173.592 635.814 162.043 620.692 162.043C605.048 162.043 595.676 173.098 595.676 186.477V186.816C595.676 200.049 605.945 211.585 621.067 211.585C636.723 211.585 646.082 200.544 646.082 187.16ZM568.681 187.16V186.816C568.681 161.041 591.01 140.11 621.067 140.11C650.762 140.11 673.077 160.533 673.077 186.477V186.816C673.077 212.601 650.762 233.517 620.692 233.517C591.01 233.517 568.681 213.11 568.681 187.16Z"
                :fill="color"/>
            <path
                d="M305.99 154.322C305.99 164.446 297.891 172.213 277.264 172.213H250.967V136.102H276.675C296.467 136.102 305.99 142.681 305.99 154.322ZM250.235 195.334H276.284C315.853 195.497 332.418 181.326 332.418 153.822V153.478C332.418 129.174 315.187 112.637 279.262 112.637H222.527V195.324V230.769H250.235V195.334Z"
                :fill="color"/>
            <path
                d="M764.124 154.325C764.124 164.449 755.823 172.217 734.696 172.217H707.731V136.101H734.08C754.35 136.101 764.124 142.67 764.124 154.325ZM706.968 195.336H733.665C774.232 195.493 791.209 181.329 791.209 153.81V153.482C791.209 129.178 773.549 112.637 736.717 112.637H678.571V195.336V230.769H706.968V195.336"
                :fill="color"/>
            <path
                d="M866.572 187.146V186.814C866.572 173.601 856.57 162.039 841.87 162.039C826.614 162.039 817.506 173.095 817.506 186.49V186.814C817.506 200.041 827.483 211.598 842.208 211.598C857.465 211.598 866.572 200.547 866.572 187.146ZM791.209 187.146V186.814C791.209 161.041 812.94 140.11 842.208 140.11C871.126 140.11 892.857 160.533 892.857 186.49V186.814C892.857 212.597 871.126 233.517 841.87 233.517C812.939 233.517 791.209 213.103 791.209 187.146Z"
                :fill="color"/>
            <path
                d="M973.314 186.791V186.467C973.314 171.57 962.768 161.696 950.283 161.696C937.812 161.696 927.447 171.57 927.447 186.467V186.791C927.447 201.688 937.812 211.562 950.283 211.562C962.768 211.562 973.314 201.862 973.314 186.791ZM901.099 141.788H927.811V154.675C934.313 146.132 943.078 140.11 957.137 140.11C979.101 140.11 1000 156.512 1000 186.467V186.791C1000 216.745 979.453 233.137 957.137 233.137C942.909 233.137 934.131 226.952 927.811 219.759V258.242H901.099V141.788Z"
                :fill="color"/>
        </svg>
        <svg v-else :width="width" viewBox="0 0 1000 259" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M102.704 219.349C134.815 219.349 159.528 219.349 159.528 219.349C164.196 219.349 167.995 215.792 167.995 211.45V33.0471C167.995 28.6993 164.196 25.1517 159.528 25.1517H35.5398C30.8582 25.1517 27.0471 28.6993 27.0471 33.0471V211.45C27.0471 215.792 30.8582 219.349 35.5398 219.349C35.5398 219.349 63.1809 219.349 92.5097 219.349V133.304C90.9009 133.304 91.0326 133.304 91.0326 133.304C84.9664 133.304 80.0474 128.727 80.0474 123.08V35.353C80.0474 29.7056 84.9664 25.1284 91.0326 25.1284H104.036C110.089 25.1284 115.008 29.7056 115.008 35.353V123.08C115.008 128.727 110.089 133.304 104.036 133.304C104.036 133.304 105.394 133.304 102.704 133.304V219.349ZM159.528 244.505H35.5398C15.93 244.505 0 229.67 0 211.45V33.0471C0 14.8227 15.93 -0.000126967 35.5398 -0.000126967H159.528C179.111 -0.000126967 195.055 14.8227 195.055 33.0471V211.45C195.055 229.67 179.111 244.505 159.528 244.505Z"
                :fill="color"/>
        </svg>
    </router-link>
</template>

<script>
    export default {
        name: 'mpp-logo-icon',

        props: {
            dark: Boolean,

            width: {
                type: [Number, String],
                default: 155
            },
            // height: {
            //     type: [Number, String],
            //     default: 48
            // },
            to: {
                type: Object,
                default() {
                    return {name: 'member-dashboard'}
                }
            }
        },
        data: () => ({
            colorLight: "#F3F9FF",
            colorDark: "#25282B"
        }),

        computed: {
            color() {
                return this.dark ? this.colorDark : this.colorLight;
            },
            isFocusDisplay() {
                return this.$vuetify.breakpoint.mdAndUp && this.$route.name === 'member-lesson-show';
            }
        },

        mounted() {

        },
    }
</script>
