<template>
    <v-card flat tile style="box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);">
        <v-row align="center">
            <v-col cols="12" sm="6" md="3">
                <v-text-field
                        style="border-radius: 48px;"
                        class="gray-input"
                        placeholder="Recherche"
                        prepend-inner-icon="mdi-magnify"
                        solo dense clearable
                        :background-color="$vuetify.theme.dark === true ? 'secondary lighten-1' : ''"
                        hide-details
                        flat
                        v-model="search.text"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2">
                <v-select
                        color="default"
                        :items="productTypesAvailable"
                        label="Type de cours"
                        item-text="title"
                        item-value="key"
                        flat dense
                        hide-details
                        chips deletable-chips
                        v-model="search.type"
                        solo
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2">
                <v-select
                        color="default"
                        :items="tagStylesAvailable"
                        item-text="title"
                        flat dense
                        item-value="id"
                        label="Parcours"
                        hide-details
                        v-model="search.styles"
                        solo multiple
                        chips deletable-chips
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="">
                <v-select
                        color="primary"
                        :items="instrumentValues"
                        item-text="name"
                        flat dense
                        item-value="id"
                        label="Instruments"
                        :loading="instrumentLoading"
                        hide-details
                        return-object
                        v-model="search.instruments"
                        solo multiple
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-btn @click="resetFilters"
                       depressed rounded small
                       color="primary"
                       class="">Retirer les filtres
                </v-btn>
            </v-col>

        </v-row>

    </v-card>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";
    import PageStore from 'mg-js-core/lib/stores/PageStore'
    import ProductFilterStore from 'mg-js-core/lib/stores/ProductFilterStore'
    import InstrumentLogic from 'mg-js-core/lib/logics/InstrumentLogic'

    export default {
        name: 'member-product-list-filters',

        data: () => ({
            Helpers,
            PageStore,
            ProductFilterStore,
            pagination: Helpers.initPagination(100, 1, 'created_at', 0),
            filters: {
                enabled: 1,
                search: '',
            },
            instrumentValues: [],
            instrumentLoading: false,
        }),

        mounted() {
            this.getAllInstruments();
        },

        computed: {
            search : {
                get(){
                    return this.ProductFilterStore.search;
                },

                set(value){
                    this.ProductFilterStore.search = value
                }
            },

            productTypesAvailable : {
                get(){
                    return this.ProductFilterStore.productTypesAvailable;
                },

                set(value){
                    this.ProductFilterStore.productTypesAvailable = value
                }
            },

            tagStylesAvailable : {
                get(){
                    return this.ProductFilterStore.tagStylesAvailable;
                },

                set(value){
                    this.ProductFilterStore.tagStylesAvailable = value
                }
            },
        },

        methods: {
            resetFilters() {
                this.search.text = "";
                this.search.type = null;
                this.search.styles = [];
                this.search.instruments = [];
            },
            getAllInstruments() {
                this.instrumentLoading = true;
                InstrumentLogic
                    .getAll(this.pagination, this.filters)
                    .then(({data}) => {
                        this.instrumentValues = data;
                        this.instrumentLoading = false;
                    })
                    .catch((err) => {
                        this.instrumentLoading = false; 
                        console.error(err)
                    })
            },
            setQuery(name, value){
                let query = Object.assign({}, this.$route.query)

                if((value && value.length) || typeof value === 'number'){
                    query[name] = value;
                } else{
                    delete query[name]
                }

                this.$router.replace({ query });
            },
        },

        watch: {
            'search.type'() {
                this.ProductFilterStore.filterProducts();
            },
            'search.text'(value) {
                this.ProductFilterStore.filterProducts();
                this.setQuery('search', value);
            },
            'search.styles'(value) {
                this.ProductFilterStore.filterProducts();

                if(this.$route.query.styles !== value){
                    this.setQuery('styles', value.toString())
                }
            },
            'search.instruments'(value) {
                this.ProductFilterStore.filterProducts();

                let values = null;
                if (value) {
                    values = value.map(item => item.id).toString();
                }

                if(this.$route.query.instruments !== values){
                    this.setQuery('instruments', values)
                }
            },
        }
    }
</script>
