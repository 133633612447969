<template>
    <div>
        <v-btn
            v-if="!notifications.length"
            icon
            :to="{name: 'member-notifications'}"
            height="36px" width="36px"
        >
            <svg
                width="36"
                height="36"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="24" cy="24" r="24" fill="#F2F2F2"/>
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.0503 15.0503C20.3631 13.7375 22.1436 13 24.0001 13C25.8566 13 27.6371 13.7375 28.9498 15.0503C30.2626 16.363 31.0001 18.1435 31.0001 20C31.0001 23.3527 31.7171 25.4346 32.378 26.6461C32.7098 27.2544 33.0329 27.6535 33.2573 27.8904C33.3698 28.0091 33.4581 28.0878 33.5114 28.1322C33.538 28.1544 33.5558 28.168 33.5635 28.1737C33.5647 28.1746 33.5657 28.1753 33.5664 28.1758C33.9249 28.4221 34.0835 28.8725 33.9572 29.2898C33.8295 29.7115 33.4407 30 33.0001 30H15.0001C14.5594 30 14.1707 29.7115 14.043 29.2898C13.9166 28.8725 14.0753 28.4221 14.4338 28.1758C14.4345 28.1753 14.4354 28.1746 14.4366 28.1737C14.4443 28.168 14.4622 28.1544 14.4888 28.1322C14.542 28.0878 14.6304 28.0091 14.7429 27.8904C14.9673 27.6535 15.2904 27.2544 15.6222 26.6461C16.283 25.4346 17.0001 23.3527 17.0001 20C17.0001 18.1435 17.7376 16.363 19.0503 15.0503ZM14.4439 28.169C14.444 28.1689 14.444 28.1688 14.4441 28.1688C14.4441 28.1688 14.4441 28.1688 14.4441 28.1688L14.4439 28.169ZM17.1494 28H30.8508C30.7747 27.8753 30.6983 27.7434 30.6222 27.6039C29.783 26.0654 29.0001 23.6473 29.0001 20C29.0001 18.6739 28.4733 17.4021 27.5356 16.4645C26.5979 15.5268 25.3262 15 24.0001 15C22.674 15 21.4022 15.5268 20.4645 16.4645C19.5269 17.4021 19.0001 18.6739 19.0001 20C19.0001 23.6473 18.2171 26.0654 17.378 27.6039C17.3019 27.7434 17.2255 27.8753 17.1494 28Z"
                    fill="#A0A4A8"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.7682 32.1352C22.2459 31.858 22.8578 32.0207 23.1349 32.4984C23.2228 32.6499 23.349 32.7757 23.5008 32.8632C23.6526 32.9506 23.8247 32.9966 23.9999 32.9966C24.1751 32.9966 24.3472 32.9506 24.4991 32.8632C24.6509 32.7757 24.777 32.6499 24.8649 32.4984C25.1421 32.0207 25.754 31.858 26.2317 32.1352C26.7094 32.4123 26.8721 33.0242 26.5949 33.5019C26.3312 33.9566 25.9527 34.3339 25.4973 34.5962C25.0419 34.8586 24.5255 34.9966 23.9999 34.9966C23.4744 34.9966 22.958 34.8586 22.5026 34.5962C22.0472 34.3339 21.6687 33.9566 21.4049 33.5019C21.1278 33.0242 21.2904 32.4123 21.7682 32.1352Z"
                    fill="#A0A4A8"
                />
            </svg>
        </v-btn>

        <v-menu
            v-else
            v-model="open"
            :close-on-content-click="false"
            bottom
            left
            nudge-bottom="50px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-badge
                    id="notification-badge"
                    :content="notifications.length"
                    :value="!!notifications.length"

                >
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        height="36px" width="36px"
                    >
                        <svg
                            width="36"
                            height="36"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle cx="24" cy="24" r="24" fill="#F2F2F2"/>
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M19.0503 15.0503C20.3631 13.7375 22.1436 13 24.0001 13C25.8566 13 27.6371 13.7375 28.9498 15.0503C30.2626 16.363 31.0001 18.1435 31.0001 20C31.0001 23.3527 31.7171 25.4346 32.378 26.6461C32.7098 27.2544 33.0329 27.6535 33.2573 27.8904C33.3698 28.0091 33.4581 28.0878 33.5114 28.1322C33.538 28.1544 33.5558 28.168 33.5635 28.1737C33.5647 28.1746 33.5657 28.1753 33.5664 28.1758C33.9249 28.4221 34.0835 28.8725 33.9572 29.2898C33.8295 29.7115 33.4407 30 33.0001 30H15.0001C14.5594 30 14.1707 29.7115 14.043 29.2898C13.9166 28.8725 14.0753 28.4221 14.4338 28.1758C14.4345 28.1753 14.4354 28.1746 14.4366 28.1737C14.4443 28.168 14.4622 28.1544 14.4888 28.1322C14.542 28.0878 14.6304 28.0091 14.7429 27.8904C14.9673 27.6535 15.2904 27.2544 15.6222 26.6461C16.283 25.4346 17.0001 23.3527 17.0001 20C17.0001 18.1435 17.7376 16.363 19.0503 15.0503ZM14.4439 28.169C14.444 28.1689 14.444 28.1688 14.4441 28.1688C14.4441 28.1688 14.4441 28.1688 14.4441 28.1688L14.4439 28.169ZM17.1494 28H30.8508C30.7747 27.8753 30.6983 27.7434 30.6222 27.6039C29.783 26.0654 29.0001 23.6473 29.0001 20C29.0001 18.6739 28.4733 17.4021 27.5356 16.4645C26.5979 15.5268 25.3262 15 24.0001 15C22.674 15 21.4022 15.5268 20.4645 16.4645C19.5269 17.4021 19.0001 18.6739 19.0001 20C19.0001 23.6473 18.2171 26.0654 17.378 27.6039C17.3019 27.7434 17.2255 27.8753 17.1494 28Z"
                                fill="#A0A4A8"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M21.7682 32.1352C22.2459 31.858 22.8578 32.0207 23.1349 32.4984C23.2228 32.6499 23.349 32.7757 23.5008 32.8632C23.6526 32.9506 23.8247 32.9966 23.9999 32.9966C24.1751 32.9966 24.3472 32.9506 24.4991 32.8632C24.6509 32.7757 24.777 32.6499 24.8649 32.4984C25.1421 32.0207 25.754 31.858 26.2317 32.1352C26.7094 32.4123 26.8721 33.0242 26.5949 33.5019C26.3312 33.9566 25.9527 34.3339 25.4973 34.5962C25.0419 34.8586 24.5255 34.9966 23.9999 34.9966C23.4744 34.9966 22.958 34.8586 22.5026 34.5962C22.0472 34.3339 21.6687 33.9566 21.4049 33.5019C21.1278 33.0242 21.2904 32.4123 21.7682 32.1352Z"
                                fill="#A0A4A8"
                            />
                        </svg>
                    </v-btn>
                </v-badge>
            </template>

            <v-card max-height="330px" flat >
                <v-list class="py-0">
                    <template v-for="(notification, index) in notifications">
                        <v-divider v-if="index > 0" :key="`divider-${index}`"/>
                        <a :href="notification.url" @click.prevent="markAsRead(notification, $event)"
                           :key="index" class="text-decoration-none">
                            <v-list-item @click="showNotificationDetail(notification)">
                                <v-list-item-avatar v-if="$vuetify.breakpoint.mdAndUp">
                                    <v-img :src="notification.avatar"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-title
                                    class="font-weight-medium notification-content"
                                    v-html="notification.title"
                                ></v-list-item-title>
                                <v-list-item-action-text v-if="notification.content" style="white-space: nowrap;" class="primary--text">
                                    Lire la suite
                                </v-list-item-action-text>

                                <v-list-item-icon class="my-auto" style="width: 30px; heith: 30px;">
                                    <v-btn
                                        icon
                                        color="primary"
                                        @click.prevent="markAsRead(notification, $event)"
                                    >
                                        <div style="height: 10px; width: 10px; border-radius: 100%;"
                                             class="primary">
                                        </div>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </a>
                    </template>
                    <v-divider v-if="notifications.length > 0"/>
                    <router-link :to="{name: 'member-notifications'}" @click="close">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title
                                    class="font-weight-medium primary--text text-center"
                                    style="white-space: normal;"
                                >Voir toutes mes notifications
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </v-list>
            </v-card>

            <v-dialog v-model="notificationDetailDialog"
                      max-width="500px" persistent>
                <v-card flat
                        :class="{'pt-9 px-10 pb-6' : $vuetify.breakpoint.smAndUp || $vuetify.breakpoint.height >= 470, 'py-4 px-2' : !$vuetify.breakpoint.smAndUp,  }">
                    <v-btn icon
                         @click="notificationDetailDialog = false; removeNotification(currentNotification)" small
                         style="position: absolute;right: 10px;top: 10px;">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <template v-if="this.currentNotification !== null">
                        <h2 class="primary--text text-h2 font-weight-bold">
                            Ta notification en détail
                        </h2>
                        <v-row align="center" class="pb-4 my-7">
                            <v-col md="2">
                                <v-avatar v-if="$vuetify.breakpoint.mdAndUp">
                                    <v-img :src="this.currentNotification.avatar"></v-img>
                                </v-avatar>
                            </v-col> 
                            <v-col md="10">
                                <div v-html="this.currentNotification.title"></div>
                            </v-col>
                        </v-row>
                        <div class="pt-3 pb-4 my-7">
                            <div v-html="this.currentNotification.content"></div>
                        </div>
                        <v-row justify="center">
                            <v-col cols="6" class="text-center" :class="{'justify-end d-flex': currentNotification.url2 && currentNotification.cta_text2 }"
                                v-if="currentNotification.url && currentNotification.cta_text">
                                <v-chip
                                        depressed large
                                        color="primary"
                                        class="text-none text-subtitle-1 text-wrap font-weight-bold ma-5"
                                        :href="currentNotification.url" target="_blank" @click="removeNotification(currentNotification)">
                                    {{ currentNotification.cta_text }}
                                </v-chip>
                            </v-col>
                            <v-col cols="6" class="text-center justify-start d-flex" v-else-if="currentNotification.url2 && currentNotification.cta_text2">
                                <v-chip
                                        depressed large
                                        color="secondary"
                                        class="text-none text-subtitle-1 text-wrap font-weight-bold ma-5"
                                        :href="currentNotification.url2" target="_blank" @click="removeNotification(currentNotification)">
                                    {{ currentNotification.cta_text2 }}
                                </v-chip>
                            </v-col>
                            <v-col cols="12" class="text-center justify-start d-flex" v-else-if="currentNotification.url">
                                <v-chip
                                        depressed large
                                        color="secondary"
                                        class="text-none text-subtitle-1 text-wrap font-weight-bold ma-5"
                                        :href="currentNotification.url" @click="removeNotification(currentNotification)">
                                    Voir
                                </v-chip>
                            </v-col>
                            <v-col cols="12" class="text-center justify-start d-flex" v-else-if="currentNotification.url2">
                                <v-chip
                                        depressed large
                                        color="secondary"
                                        class="text-none text-subtitle-1 text-wrap font-weight-bold ma-5"
                                        :href="currentNotification.url2" @click="removeNotification(currentNotification)">
                                    Voir
                                </v-chip>
                            </v-col>
                        </v-row>
                    </template>
                </v-card>
            </v-dialog>


        </v-menu>
    </div>
</template>

<style lang="scss">
#notification-container {
    max-width: 30rem;
    min-width: 10rem;
    position: absolute;
    position: absolute;
    max-height: 330px;
    overflow-y: auto;
    z-index: 10;
    top: 2.5rem;
    right: -1rem;
    //width: 30rem;
}

#notification-badge .v-badge__badge {
    bottom: calc(100% - 12px) !important;
    left: calc(100% - 12px) !important;
    background: #f24921 !important;
}

#notification-badge.hide .v-badge__badge {
    background: transparent !important;
}

.notification-content {
    white-space: normal;
}

.notification-content p {
    margin-bottom: 0;
}
</style>

<script>
    import WebNotificationLogic from "mg-js-core/lib/logics/WebNotificationLogic";
    import {mixin as clickaway} from 'vue-clickaway';
    import {EventBus} from "mg-js-core/lib/services/Request";

    export default {
        name: "user-notification-container-refact",

        mixins: [clickaway],

        components: {},

        data: () => ({
            notifications: [],
            open: false,
            notificationDetailDialog: false,
            currentNotification: null,
        }),

        created() {
            EventBus.$on('refresh-notification', () => {
                this.getWebNotifications();
            });

            this.getWebNotifications();
            this.interval = setInterval(function() {
                if (!document.hidden) {
                    this.getWebNotifications();
                }
            }.bind(this), 30000);
        },

        destroyed() {
            clearInterval(this.interval);
        },

        computed: {
            notificationsLength() {
                return this.notifications.length;
            },
            hasNotifications() {
                return this.notificationsLength > 0;
            }
        },

        methods: {
            toggle() {
                this.open = !this.open;
            },
            close() {
                this.open = false;
            },
            removeNotification(id) {
                const index = this.notifications.findIndex(notification => notification.id === id);
                this.notifications.splice(index, 1);
            },
            markAsRead(notification, e = null) {
                if (e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                }
                WebNotificationLogic.markAsRead({
                    id: [notification.id]
                });
            },
            getWebNotifications() {
                WebNotificationLogic.get(
                    {
                        include: "",
                        read: true
                    }
                ).then(({data}) => {
                    this.notifications = data;
                }).catch(() => clearInterval(this.interval));
            },
            closeContainer() {
                this.open = false;
            },
            showNotificationDetail(notification) {
                this.currentNotification = notification;
                this.notificationDetailDialog = true;
            }
        }
    };
</script>
