import Vue from 'vue'
import Router from 'vue-router'
import PageStore from 'mg-js-core/lib/stores/PageStore'

import MemberRoute from '@/router/member'
import PublicRoute from '@/router/public'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        ...MemberRoute,
        ...PublicRoute,
        {
            name: 'the-other-platform',
            path: process.env.VUE_APP_THE_OTHER_PLATFORM_URL,
            beforeEnter() {
                location.href = process.env.VUE_APP_THE_OTHER_PLATFORM_URL
            }
        }
    ],
    scrollBehavior() {
        // retourne la position de défilement souhaitée
        return { x: 0, y: 0 };
        },
    meta: []
});

router.beforeEach((to, from, next) => {
    // handle referral link
    if (to.query.referral) {
        // get referrer id
        const referrer = to.query.referral;

        // create expiration cookie date to now + 1 month
        var expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth() + 1);

        // store cookie
        document.cookie = `referrer=${referrer}; expires=${expiryDate.toUTCString()}; path=/`
    }
    if (!process.env.VUE_APP_MAINTENANCE) {
        PageStore.resetPageInfo();
        next()
    } else {
        to.name !== 'maintenance'
            ? next({name: 'maintenance'})
            : next()
    }
});

export default router;
