<template>
  <v-container>
    <div @click="handleSearch">
      <v-text-field
          style="border-radius: 48px;"
          class="gray-input"
          placeholder="Recherche"
          prepend-inner-icon="mdi-magnify"
          :background-color="$vuetify.theme.dark === true ? 'secondary lighten-1' : ''"
          solo dense clearable
          hide-details
          flat
          v-model="searchQuery"
      ></v-text-field>
    </div>
    <v-dialog v-model="showModal" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          Votre recherche
          <v-spacer></v-spacer> <!-- Cela pousse le bouton de fermeture à droite -->
          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            style="border-radius: 48px;"
            class="gray-input"
            placeholder="Recherche"
            prepend-inner-icon="mdi-magnify"
            :background-color="$vuetify.theme.dark === true ? 'secondary lighten-1' : ''"
            solo dense clearable
            hide-details
            flat
            v-model="searchQuery"
            autofocus
            @input="performSearch"
          ></v-text-field>

          <v-row
              class="text-center ">
              <v-col align-self="center">
                  <v-progress-circular
                      v-if="loading"
                      indeterminate
                      color="primary"
                      class="mt-2"
                  ></v-progress-circular>
              </v-col>
          </v-row>

          <div class="mt-4" v-if="data?.userCourses?.data">
            <div v-if="data.products.data.length">
              <h2 class="mt-2 mb-2">Cours disponibles</h2>
              <mg-product-item
                  v-for="product in data.products.data"
                  @closeGlobalSearch="closeModal"
                  :key="product.id"
                  :value="product"
                  class="mb-2"
                  :hideImageForMobile="true"
              ></mg-product-item>
            </div>
            <div v-if="data.userCourses.data.length">
              <h2 class="mt-2 mb-2">Mes cours</h2>
              <course-item
                  class="mb-2"
                  @closeGlobalSearch="closeModal"
                  v-for="course in data.userCourses.data"
                  :key="course.id"
                  flat
                  :course="course"
                  :routeNameShowCourse="routeNameShowCourse"
                  :routeNameShowCourseModule="routeNameShowCourseModule"
                  :routeNameShowLesson="routeNameShowLesson"
                  :showCourseProgression="true"
                  :showFavoriteBtn="false"
                  :showCompleteBtn="false"
                  :hideImageForMobile="true"
                  >
              </course-item>
            </div>
            <div v-if="data.repertorySongs.data.length">
              <h2 class="mt-2 mb-2">Chansons MyRépertoire</h2>
              <v-list style="background-color: transparent;" flat>
                  <v-row class="mb-2" v-for="(song, index) in data.repertorySongs.data" :key="index">
                      <v-col cols="10">
                          <v-list-item @click="goToSong(song)">
                                  <v-row>
                                      <v-col class="d-flex" cols="12" md="6">
                                          <img height="30" width="30" class="mr-2" :src="require(`@/assets/mediator/${song.tag_level.id}.png`)"/>
                                          <div class="d-flex flex-column">
                                              <h3>{{ song.title }} <span v-if="song.repertory_is_new" style="font-size: 12px;background: #2287E5;color: white;padding: 5px;border-radius: 30px;">Nouveau</span></h3>
                                              <p class="text-caption font-italic mb-1">{{ song.repertory_author }}</p>
                                          </div>
                                      </v-col>
                                      <v-col class="hidden-sm-and-down">
                                          {{ song.tag_level.title }}
                                      </v-col>
                                      <v-col class="hidden-sm-and-down">
                                          {{ song.tag_style.title }}
                                      </v-col>
                                  </v-row>
                          </v-list-item>
                      </v-col>
                  </v-row>
              </v-list>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GlobalSearchLogic from 'mg-js-core/lib/logics/GlobalSearchLogic'
import CourseItem from "@/components/course/CourseItem.vue";
import _ from 'lodash';
import MgProductItem from "@/components/product/MgProductItem";
import AuthLogic from 'mg-js-core/lib/logics/AuthLogic';

export default {
  components: {
    CourseItem,
    MgProductItem,
  },
  data() {
    return {
      searchQuery: '',
      showModal: false,
      loading: false,
      data: null,
      routeNameShowCourse: 'member-course-show',
      routeNameShowCourseModule: 'member-course-module-show',
      routeNameShowLesson: 'member-lesson-show',
      user: AuthLogic.me(),
      selectedSong: null,
    };
  },
  methods: {
    handleSearch() {
      this.showModal = true;
    },
    performSearch: _.debounce(function() {
      if (this.searchQuery.length > 3) {
        this.loading = true;
        GlobalSearchLogic.search({
          search: this.searchQuery,
        })
          .then((data) => {
              this.data = data;
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
          })
      }
    }, 300),
    closeModal() {
      this.searchQuery = '';
      this.data = null;
      this.showModal = false;
    },
    onKeydown(e) {
      if (e.key === "Escape") {
        this.closeModal();
      }
    },
    goToSong(song) {
        this.selectedSong = song;
        if (this.user.has_repertory_subscription || this.selectedSong.member_has_this_module) {
            this.$router.push({name: 'repertory-lesson', params: {courseModuleSlug: song.slug, lessonSlug: song.lessons.data[0].slug}})
        } else {
            this.$router.push({name: 'member-course-list', params: {courseTab: 'repertory'}, query: {search: this.selectedSong.title}})
        }
    },
  },
  computed: {
    songPartsDialogTableElements() {
        let data = [];
        if (this.selectedSong) {
            this.selectedSong.songParts.data.forEach(songPart => {
                songPart.lessons.data.forEach(lesson => {
                    data.push({
                        part: songPart.title,
                        lesson: lesson.title,
                        difficulty: songPart.formated_difficulty,
                        action: `/repertory/lesson/${lesson.slug}`,
                    });
                });
            });
        }

        return data;
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
  },
  watch: {
    searchQuery(value) {
        if (value.length < 5) {
          this.data = null;
        }
    }
  },
};
</script>
