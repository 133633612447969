import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=05b581f6&scoped=true&"
import script from "./AppFooter.vue?vue&type=script&lang=js&"
export * from "./AppFooter.vue?vue&type=script&lang=js&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=05b581f6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b581f6",
  null
  
)

export default component.exports