<template>
    <v-card v-if="settings && (($platformStore.notMyPianoPop && settings.is_show_map) || (!$platformStore.notMyPianoPop && settings.mpp_is_show_map)) && user.address" flat tile style="layout-padding-x box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);">
        <v-row class="align-center">
            <v-col cols="12" sm="6" md="3" lg="4" class="">
                <v-autocomplete
                    v-model="search.username"
                    :items="userResults"
                    :loading="userLoading"
                    :search-input.sync="userSearch"
                    class="gray-input"
                    style="border-radius: 48px;"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    hide-details
                    hide-selected
                    item-text="username"
                    item-value="username"
                    label="Pseudo"
                    no-data-text=""
                    solo
                    flat
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="4" class="">
                <v-autocomplete
                    v-model="search.city"
                    :items="cityResults"
                    :loading="cityLoading"
                    :search-input.sync="citySearch"
                    class="gray-input"
                    style="border-radius: 48px;"
                    prepend-inner-icon="mdi-magnify"
                    clearable
                    hide-details
                    hide-selected
                    item-text="city"
                    item-value="city"
                    label="Ville"
                    no-data-text=""
                    solo
                    flat
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="2" lg="2" class="">
                <v-checkbox
                    v-model="search.is_active"
                    label="Afficher uniquement les membres actifs"
                ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2" 
                    class="d-flex" 
                    :class="{
                        'align-content-center' : $vuetify.breakpoint.smAndDown
                    }">
                <v-btn @click="doSearch"
                        depressed rounded small
                        color="primary"
                        class="">Rechercher
                </v-btn>
                <v-btn @click="resetFilters"
                        depressed rounded small
                        color="secondary"
                        class="ml-2">Retirer les filtres
                </v-btn>
            </v-col>
        </v-row>

    </v-card>
</template>

<style lang="scss">
.theme--dark {
    .v-input textarea {
        color: var(--v-default-base);
    }
}
</style>

<script>
import Helpers from "mg-js-core/lib/services/Helpers";
import UserLogic from "mg-js-core/lib/logics/UserLogic";
import AuthLogic from 'mg-js-core/lib/logics/AuthLogic';
import MapLogic from "mg-js-core/lib/logics/MapLogic";
import _ from 'lodash';

    export default {
        name: 'map-filters',

        data: () => ({
            Helpers,
            search: {
                is_active: null,
                username: null,
                city: null,
            },
            settings: null,
            user: AuthLogic.me(),
            userSearch: null,
            userResults: [],
            userLoading: false,
            citySearch: null,
            cityResults: [],
            cityLoading: false,
        }),
        mounted() {
            this.getSettings();
        },

        methods: {
            resetFilters() {
                this.search.username = null;
                this.search.city = null;
                this.search.is_active = null;
            },

            doSearch() {
                if (this.search.username) {
                    this.setQuery('username', this.search.username);
                }

                if (this.search.city) {
                    this.setQuery('city', this.search.city);
                }

                if (this.search.is_active) {
                    this.setQuery('is_active', this.search.is_active);
                }
            },

            setQuery(name, value){
                let query = Object.assign({}, this.$route.query)

                if((value && value.length) || typeof value === 'number' || typeof value === 'boolean'){
                    query[name] = value;
                } else{
                    delete query[name]
                }

                this.$router.replace({ query });
            },
            getSettings() {
                UserLogic
                    .getMeSettings()
                    .then((data) => {
                        if (data.data) {
                            this.settings = data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            },
            searchUsers: _.debounce(function (search) {
                this.userLoading = true;
                MapLogic.autocompleteUsers({search})
                    .then(({ data }) => {
                        this.userResults = data;
                        this.userLoading = false;
                    })
                    .catch(err => {
                    console.error(err);
                    });
            }, 300),
            searchCities: _.debounce(function (search) {
               this.cityLoading = true;
                MapLogic.autocompleteCities({search})
                    .then(({ data }) => {
                        this.cityResults = data;
                        this.cityLoading = false;
                    })
                    .catch(err => {
                    console.error(err);
                    });
            }, 300),
        },
        watch: {
            'search.username'(value) {
                if (!value) {
                    this.setQuery('username', null);
                }
            },
            'search.city'(value) {
                if (!value) {
                    this.setQuery('city', null);
                }
            },
            'search.is_active'(value) {
                if (!value) {
                    this.setQuery('is_active', null);
                }
            },
            userSearch: {
                handler(value) {
                    if (value && value.length > 1) {
                        this.searchUsers(value);
                    }
                }
            },
            citySearch: {
                handler(value) {
                    if (value && value.length > 1) {
                        this.searchCities(value);
                    }
                }
            },
        }
    }
</script>
