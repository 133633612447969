<template>
    <div>
        <v-label :for="id">
            {{ label }} <span v-if="required" style="color: red;">*</span>
        </v-label>

        <v-text-field
                :id="id"
                v-bind="$attrs"
                v-on="$listeners"
                :class="{'mt-2' : !!label}"
                :background-color="inputIsValid ? backgroundColorValid : null"
                :flat="typeof $attrs.flat === 'boolean' ? $attrs.flat : true"
                :outlined="typeof $attrs.outlined === 'boolean' ? $attrs.outlined : true"
                :solo="typeof $attrs.solo === 'boolean' ? $attrs.solo : true"
                :dense="typeof $attrs.dense === 'boolean' ? $attrs.dense : true"
                :label="null">
            <!-- Pass on all named slots -->
            <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>

            <!-- Pass on all scoped slots -->
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>

        </v-text-field>
    </div>
</template>

<script>
    import Helpers from "mg-js-core/lib/services/Helpers";

    export default {
        name: 'mg-text-field',

        props: {
            id: {
                type: String,
                default() {
                    return Helpers.uuidv4()
                }
            },
            label: String,
            isValid: Boolean,
            field: Object,
            required: Boolean,
        },

        data: () => ({
            backgroundColorValid : 'primary lighten-5'
        }),

        watch : {
            '$vuetify.theme.dark'(value){
                this.backgroundColorValid = value === true ? 'secondary lighten-1' : 'primary lighten-5';
            }
        },

        created() {
            this.backgroundColorValid = this.$vuetify.theme.dark === true ? 'secondary lighten-1' : 'primary lighten-5';
        },

        computed: {
            inputIsValid() {
                return this.field ? this.field.valid : false;
            },
        },
    };
</script>
