<template>
    <v-alert
        density="compact"
        type="danger"
        icon="mdi-alert"
    >
        Pour conserver l'accès à l'application, valide ton adresse mail via le lien envoyé à ton inscription. 
        <span class="font-weight-bold" v-if="resendSuccess">Nous venons de te renvoyer le lien. Pense à vérifier tes spams</span>
        <span class="font-weight-bold" v-else-if="resendNeedWait">Merci de patienter 5 minutes avant de renvoyer le lien</span>
        <span class="font-weight-bold" v-else-if="resendLoading">Envoi en cours...</span>
        <span v-else>Nous pouvons te le renvoyer en <strong @click="resend()" style="cursor: pointer;">cliquant ici</strong></span>
        .
    </v-alert>
</template>

<script>
import AuthLogic from 'mg-js-core/lib/logics/AuthLogic'

export default {
    name: 'ValidateEmailBanner',
    data: () => ({
        resendLoading: false,
        resendSuccess: false,
        resendNeedWait: false,
    }),
    methods: {
        resend() {
            AuthLogic
                .resendValidationEmail()
                .then(() => {
                    this.resendLoading = false;
                    this.resendSuccess = true;
                })
                .catch(() => {
                    this.resendLoading = false;
                    this.resendNeedWait = true;
                });
        },
    },
}
</script>
